import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';

// eslint-disable-next-line no-new
new Swiper('.js-swiper', {
  modules: [Navigation, Pagination],
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  autoplay: {
    delay: 5000
  }
});

const ThumbSliderThumbs = new Swiper('.js-swiper-thumbs', {
  spaceBetween: 20,
  slidesPerView: 'auto',
  freeMode: true,
  watchSlidesProgress: true
});

const ThumbSlider = new Swiper('.js-swiper-with-thumbs', {
  modules: [Navigation, Thumbs],
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  thumbs: {
    swiper: ThumbSliderThumbs
  }
});
