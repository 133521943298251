/* eslint-disable no-param-reassign */

import { Deserializer } from 'jsonapi-serializer';
import Swiper, { Navigation, Pagination } from 'swiper';

const apiUrl = window.gon && window.gon.global.advert.apiUrl;
const adsenseEnabled = window.gon && window.gon.global.advert.adsenseEnabled;
const bannerPositions = document.querySelectorAll('.js-company-img');
const bannerCompanyPositions = document.querySelectorAll('.js-company-img-extended');

const renderSlide = ({ imageUrl, url }) => `
  <a href="${url}" target="_blank" class="swiper-slide"><img src="${imageUrl}" alt=""></a>
`;

const renderSlider = banners => `
  <div class="swiper-wrapper">${banners.map(banner => renderSlide(banner)).join('')}</div>
  <div class="swiper-pagination"></div>
  <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div>
`;

const buildBanners = ({ postings }) => postings.map(p => ({
  url: p.banner.url,
  imageUrl: p.banner.imageUrl,
  position: p.position || 999
}));

const layoutWrapper = (item, callback) => {
  const banners = buildBanners(item);
  const bannerEl = document.querySelector(`div[data-slug=${item.slug}]`);

  if (bannerEl) {
    if (banners.length) {
      bannerEl.classList.remove('hidden');
      return callback(banners, bannerEl);
    } else if (adsenseEnabled && item.adsenseCode && !bannerEl.dataset.bg) {
      const parentEl = bannerEl.parentNode;
      parentEl.innerHTML = item.adsenseCode;
      bannerEl.remove();
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }
};

const layoutSlider = item => layoutWrapper(item, (banners, el) => {
  el.innerHTML = renderSlider(banners.sort((a, b) => a.position - b.position));
  el.classList.add('swiper');
  // eslint-disable-next-line no-new
  new Swiper(el, {
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    autoplay: {
      delay: 5000
    }
  });
});

const layoutRandom = item => layoutWrapper(item, (banners, el) => {
  const banner = banners[Math.floor(Math.random() * banners.length)];

  if (el.dataset.bg) {
    el.querySelector('a').href = banner.url;
    el.querySelector('div').style.backgroundImage = `url('${banner.imageUrl}')`;
  } else {
    el.innerHTML = renderSlide(banner);
  }
});

const handleBanners = items => items.forEach(item => {
  switch (item.layout) {
  case 'slider':
    layoutSlider(item);
    break;

  default: // random
    layoutRandom(item);
    break;
  }
});

const filters = (banners) => {
  const slugs = Array.from(banners).map(banner => banner.dataset.slug).join();
  const publicIds = Array.from(banners).map(banner => banner.dataset.companyPublicIds).filter(Boolean).join();
  let result = '';
  if (slugs) result = `&filter[slug]=${slugs}`;
  if (publicIds) result = `&filter[postings.company_public_id]=${publicIds}`;
  return result;
};

const fetchBanners = async (banners) => {
  try {
    const response = await fetch(`${apiUrl}/places?include=postings.banner${filters(banners)}`);
    const items = await new Deserializer({ keyForAttribute: 'camelCase' }).deserialize(await response.json());
    handleBanners(items);
  } catch (error) {
    console.error(`Can't fetch data: ${error.message}`);
  }
};

if (bannerPositions.length) fetchBanners(bannerPositions);
if (bannerCompanyPositions.length) fetchBanners(bannerCompanyPositions);
