/* eslint-disable */

const img = require.context('../img', true);
import '../stylesheets/application';
import 'swiper/swiper.min';

import './components/ShareButton';
import './components/Navigation';
import './components/Toggle';
import './components/Banners';
import './components/Swiper';
import './components/PayWall';
